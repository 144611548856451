@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

body {
  background-color: #222831;
  color: white;
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  user-select: none;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

a {
  all: unset;
}

.basic-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

#header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  height: 100px;
  width: 80vw;
  margin: auto;
}

#header h1,
h4 {
  margin: 0;
  padding: 0;
}

#nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 400px;
  position: absolute;
  top: 50%;
  right: 0%;
  /* background-color: black; */
}

#nav p {
  cursor: pointer;
}

#sketch-container {
  position: absolute;
  z-index: -2;
  top: 0%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* filter: blur(3px); */
  /* opacity: 0.8; */
}

@media only screen and (max-width: 600px) {
  #sketch-container {
    /* filter: blur(5px); */
    /* opacity: 0.8; */
  }
}

#about-me {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  gap: 50px;
  margin-left: 5%;
  margin-right: 5%;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  padding-left: 20px;
  padding-right: 20px;
  max-width: 500px;
  background-color: black;
}

#about-me h1,
h2,
h3,
h4,
h5,
p {
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.font-smaller {
  font-size: 0.7em;
}

#project-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  width: 80vw;
  max-width: 900px;
  gap: 30px;
  height: 75vh;
  overflow-y: auto !important;
  /* needs fixing */
}

.r-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.r-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
